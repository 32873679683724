@import "styles/main.scss";

.layoutWrapper {
  @include container;

  :global {
    .layout-head {
      padding: $space-10 0 $space-5;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto $space-52;

      @include responsive(tablet) {
        padding: $space-12 0 $space-6;
        width: 65%;
      }

      @include responsive(small) {
        margin: 0 auto $space-16;
      }

      .logo {
        width: 14rem;
      }

      .icon-back {
        // transform: rotate(-90deg) !important;
        font-size: 2.4rem;
        color: $text;
        cursor: pointer;

        @include responsive(tablet) {
          position: absolute;
          left: -10rem;
          top: 55%;
          transform: translateY(-50%);
        }

        @include responsive(small) {
          left: -15rem;
        }
      }
    }

    .layout-content {
      margin: 0 auto;
      padding-bottom: 24rem;

      @include responsive(tablet) {
        width: 65%;
      }

      .date-text {
        margin-bottom: $space-12;
        font-weight: bold;
      }

      .sub-text {
        font-weight: bold;
        margin: $space-6 0;
      }

      p {
        font-family: "Montserrat", sans-serif;
        line-height: 1.7;
      }

      .text-hightlight {
        font-weight: bold;
      }
    }
  }
}
