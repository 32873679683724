@font-face {
  font-family: "Unbounded";
  src: url("../assets/fonts/Unbounded-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Unbounded";
  src: url("../assets/fonts/Unbounded-SemiBold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-SemiBold.ttf");
  font-weight: bold;
}
