@import "styles/main.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-5 $space-8;
  border-radius: $radius-sm1;
  width: max-content;
  min-width: 20rem;
  border: 3px solid $black;
  // border-bottom-width: 8px;
  box-shadow: 0px 8px $black;
  cursor: pointer;
  transition: 0.3s background-color;
  background-color: $yellow;
  height: 8rem;
  transform: translateY(-8px);
  gap: $space-1;
  white-space: nowrap;
  color: $text;

  @include responsive(small) {
    &:hover {
      box-shadow: 0px 0px $black;
      transform: translateY(0);
      background-color: $yellow-hover;
    }
  }

  &:active {
    background-color: $yellow-secondary;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default;
  }

  &--secondary {
    background-color: $white;

    @include responsive(small) {
      &:hover {
        background-color: $grey3;
      }
    }

    &:active {
      background-color: $grey2;
    }
  }

  &--circle {
    min-width: unset;
    width: 8rem;
    height: 8rem;
    padding: 0;
    border-radius: 50%;
  }

  &--square {
    min-width: unset;
    width: 6rem;
    height: 6rem;
    padding: 0;

    @include responsive(tablet) {
      width: 8rem;
      height: 8rem;
    }
  }
}

.link-disable {
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}

a {
  button {
    background-color: transparent;
    border: none;
    outline: none;
  }
}
