@import './main.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  background: #c7eeff;
  color: $text;
  scroll-behavior: smooth;
  font-family: 'Unbounded', sans-serif;
  // height: 100vh;
  overflow-x: hidden;
  // overflow-y: auto;
  line-height: 1.4;
  width: 100%;

  // @include responsive(normal) {
  //   overflow-x: unset;
  // }
}

html {
  font-size: 10px;
  height: 100%;
  height: auto;
  width: 100%;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  width: 100%;
  // overflow: hidden;
  font-weight: 400;
}

a {
  color: $text;
  text-decoration: none;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    // color: $second;
  }
}

.top-header {
  padding-top: 9.6rem;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.app-body {
  &--home {
    margin-top: -12.4rem;

    @include responsive(tablet) {
      margin-top: -14.4rem;
    }
  }

  .banner-run-bottom {
    padding: $space-5 0;
    background-color: $white;
    border-bottom: 4px solid $black;
    border-top: 3px solid $black;

    .item-banner {
      display: flex;
      align-items: center;
      gap: $space-2;

      .icon-banner {
        width: 4.8rem;
        margin: 0 $space-2;
      }
    }
  }

  .banner-social {
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 0;
    background-color: $black;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10rem 0;

    @include responsive(small) {
      padding: 0;
    }

    .bg-text {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: -1;
      object-fit: cover;
    }

    .banner-social-container {
      @include container;

      h3 {
        @include titleText;
        margin-bottom: 10rem;
        text-align: center;
      }

      .list-social {
        display: grid;
        grid-template-columns: 1fr;
        gap: $space-8;
        width: 70%;
        margin: 0 auto;
        margin-bottom: $space-20;

        @include responsive(tablet) {
          grid-template-columns: repeat(2, 1fr);
        }

        .item-social {
          padding: $space-6;
          border-radius: $radius-sm1;
          background-color: $white;
          height: 100%;
          transition: 0.3s all;

          &:hover {
            transform: translateY(-5px);
          }

          .head-icon {
            display: flex;
            justify-content: space-between;
            margin-bottom: $space-8;

            .icon-social {
              font-size: 5.6rem;

              &--discord {
                color: #526cec;
              }

              &--fin-wallet {
                color: #35dbce;
              }
            }

            .icon-arrow {
              font-size: 2.4rem;
            }
          }

          .text-description {
            font-family: 'Montserrat';
            margin-top: $space-1;
          }
        }
      }

      .list-footer {
        display: grid;
        grid-template-columns: 1fr;
        gap: $space-4;

        @include responsive(tablet) {
          grid-template-columns: repeat(3, 1fr);
          gap: 0;
        }

        .item-footer {
          color: $white;
          font-size: 2rem;
          text-align: center;
          position: relative;
          transition: 0.3s all;

          &:hover {
            color: $grey3;
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 70%;
            background-color: $white;
            display: none;

            @include responsive(tablet) {
              display: block;
            }
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.top-icon {
  position: fixed;
  bottom: -10rem;
  right: 2.4rem;
  cursor: pointer;
  z-index: 999;
  transition: 0.3s all;

  &--show {
    bottom: 5rem;
  }

  .button-custom {
    width: 5.6rem;

    &--hover {
      display: none;
    }

    // &:active {
    //   display: none;

    //   &--hover {
    //     display: block;
    //   }
    // }
  }
}

.action-music {
  width: 5rem;
  height: 5rem;
  position: fixed;
  left: 2rem;
  bottom: 5rem;
  cursor: pointer;
  // background-color: rgba($color: #ffffff, $alpha: 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50%;
  transition: 0.3s width, 0.3s border-radius;
  overflow: hidden;
  padding: 0;
  padding-left: 0.75rem;
  min-width: unset !important;
  box-shadow: 0px 4px $black;
  border-width: 2px;

  .music-icon {
    min-width: 3.2rem;
    height: 3.2rem;
  }

  .music-name {
    white-space: nowrap;
    font-size: 1.6rem;
    width: 0;
    overflow: hidden;
    transition: 0.3s all;
    margin-left: 0;
  }

  @include responsive(normal) {
    &:hover {
      width: 28rem;
      border-radius: 999px;
      transform: translateY(-8px);
      background-color: $white;
      box-shadow: 0px 4px $black;

      .music-name {
        width: max-content;
        // margin-left: $space-2;
      }
    }
  }
}
