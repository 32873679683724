@import "styles/main.scss";

.licenseWrapper {
  :global {
    .sub-text-2 {
      font-weight: bold;
      margin: $space-6 0;
      font-family: "Montserrat", sans-serif;
    }

    ul {
      padding-left: 2rem;
    }
  }
}
