$text: #1b1b1b;

$black: #1b1b1b;
$grey: #686868;
$grey1: #8f8f8f;
$grey2: #a4a4a4;
$grey3: #c0c0c0;
$white: #ffffff;
$red: #ff5d60;
$green: #5fff90;
$purple: #c1a9fd;
$blue: #00cec2;
$orange: #ffb96c;
$yellow-secondary: #ffe675;
$yellow-hover: #e59b00;
$yellow: #fba900;
