@import "styles/main.scss";

.itemInfoWrapper {
  width: 100%;
  position: relative;
  z-index: 0;
  // height: 65rem;

  @include responsive(tablet) {
    height: unset;
  }

  :global {
    .img-info {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 1400px;

      @include responsive(tablet) {
        width: 2000px;
      }

      @include responsive(small) {
        // position: unset;
        width: 110%;
        transform: translatey(-10%) translateX(-50%);
      }
    }

    .item-info {
      background-color: $white;
      padding: $space-2;
      border-radius: $radius-lg2;
      position: absolute;
      bottom: -15rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      width: 100%;

      @include responsive(tablet) {
        bottom: -5rem;
        width: calc(100% - 4.8rem);
      }

      @include responsive(small) {
        width: 110%;
      }

      .content-item {
        // min-height: 15rem;
        width: 100%;
        position: relative;
        z-index: 0;
        border-radius: $radius-md;
        overflow: hidden;

        .bg-content {
          width: 300%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          object-fit: cover;
        }

        .head-title {
          padding: $space-6 $space-6 $space-8;
          border-radius: $radius-md;
          // display: grid;
          // grid-template-columns: 1fr;
          // gap: $space-4;

          // @include responsive(small) {
          // gap: 0;
          // grid-template-columns: repeat(2, 1fr);
          // }

          .text-description {
            font-family: Montserrat;
            font-size: 1.8rem;
          }
        }

        .content-item-body-wrapper {
          padding: $space-3 $space-6;
          border-radius: $radius-md;
          background-color: $white;
          grid-template-columns: 1fr;
          gap: $space-8;
          display: none;

          @include responsive(tablet) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }

          @include responsive(small) {
            grid-template-columns: repeat(4, 1fr);
          }

          .content-item-body {
            font-family: Montserrat;
            font-size: 1.4rem;

            &--child {
              margin-bottom: $space-8;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .content-item-body__head {
              display: flex;
              align-items: center;
              gap: $space-1;
              margin-bottom: $space-1;

              .icon-star {
                color: $red;
                font-size: 1.6rem;
              }

              .sub-title {
                font-size: 1.6rem;
                font-weight: bold;
                font-family: "Unbounded";
              }
            }

            .content-item-body__text {
              padding-left: calc(1.6rem + 4px);
            }
          }
        }
      }
    }
  }
}
