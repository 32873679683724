@import 'styles/main.scss';

.headerWrapper {
  width: 100%;
  z-index: 0;
  padding: $space-8 0;
  // background: linear-gradient(180deg, #a8ddfb 0%, #a8dcfb00 100%);
  // overflow: hidden;
  // position: relative;
  top: 0;
  left: 0;
  z-index: 999;
  position: relative;
  // overflow: hidden;

  @include responsive(small) {
    background-color: transparent;
  }

  :global {
    .button-menu {
      margin-left: auto;
      margin-right: $space-5;
      z-index: 999;

      &--close {
        margin-top: $space-8;
        margin-bottom: $space-12;
      }

      .icon-menu {
        font-size: 3rem;

        @include responsive(tablet) {
          font-size: 4rem;
        }
      }
    }

    .header {
      height: 100vh;
      background-color: #a9dffb;
      width: 100%;
      z-index: 888;
      top: 0;
      left: 100%;
      transition: 0.3s left;
      position: absolute;
      // overflow: hidden;

      @include responsive(small) {
        overflow: unset;
        position: unset;
        padding-top: 0;
        background-color: transparent;
        height: unset;
      }

      &--show-menu {
        left: 0;
        overflow: hidden;
      }

      .header-container {
        @include container;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $space-5 $space-2;

        @include responsive(small) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .button-custom {
          font-weight: bold;

          &--full {
            grid-column: 1 / span 2;

            width: 100%;

            @include responsive(small) {
              width: unset;
            }
          }

          &--coming-soon {
            pointer-events: none;
            flex-direction: column;
          }

          span {
            font-weight: normal;
            color: $grey;
          }
        }

        .button-social {
          width: 100%;

          @include responsive(small) {
            width: 8rem;
          }

          &--discord {
            color: #526cec;
          }
        }

        .icon-social {
          font-size: 4rem;
        }
      }
    }
  }
}
