@import "styles/main.scss";

.item-card {
  width: 35rem;
  height: 45rem;

  &--flip {
    .img-card {
      transform: rotateY(180deg);
    }
  }

  @include responsive(small) {
    &:hover {
      .img-card {
        transform: scale(1.1);
        transform: rotateY(180deg);
      }
    }
  }

  .img-card {
    border-radius: 50%;
    background-color: var(--bg-container);
    display: flex;
    align-items: center;
    justify-content: center;
    transform-style: preserve-3d;
    position: relative;
    transition: 0.5s all;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .img-card-content {
      width: 100%;

      &--font {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
      }

      &--back {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        white-space: nowrap;
        transform: rotateY(180deg);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
      }
    }
  }
}
