@import 'styles/main.scss';

.landingBody {
  overflow-x: hidden;
  overflow-y: unset;

  :global {
    .banner {
      width: 100vw;
      // height: calc(100vh - 14rem);
      // height: 100vh;
      background-color: $white;
      position: relative;
      z-index: 0;

      @include responsive(small) {
        height: 100vh;
      }

      .logo {
        position: absolute;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        top: 15%;
        width: 45%;

        @include responsive(small) {
          width: 25rem;
          top: 20%;
        }
      }

      .banner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .logo-box {
        width: 100%;
        display: flex;
        justify-content: center;

        .logo-banner {
          width: 70%;
        }
      }

      .direction-icon {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        display: flex;

        &:hover {
          .button-custom {
            display: none;

            &--hover {
              display: block;
            }
          }
        }

        .button-custom {
          width: 6rem;

          &--hover {
            display: none;
          }
        }

        .cloud {
          position: absolute;
          width: 200%;
          bottom: -3rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
    }

    .body-content {
      position: relative;
      z-index: 0;
      background: linear-gradient(
        180deg,
        #77d8fd 0%,
        #3b9eff 13.37%,
        #066cff 29.33%,
        #066cff 49.55%,
        #066cff 74.93%,
        #0767f3 89.05%,
        #07377c 100%
      );

      .img-bg {
        position: absolute;

        &--fish-1 {
          top: 20rem;
          left: 0;
          z-index: -1;
          width: 30rem;

          @include responsive(small) {
            width: 30%;
          }
        }
        &--fish-2 {
          bottom: 35rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
          width: 35rem;

          @include responsive(small) {
            bottom: 45rem;
            width: 35%;
          }
        }
        &--fish-3 {
          top: 36%;
          right: 0;
          z-index: -1;
          width: 25rem;

          @include responsive(tablet) {
            top: 24%;
            right: 0;
            width: 30%;
          }
        }
        &--fish-4 {
          top: 45%;
          left: 10%;
          z-index: -1;
          width: 15%;

          @include responsive(tablet) {
            top: 31%;
            left: 10%;
          }

          @include responsive(small) {
            width: 5%;
            top: 35%;
            left: 25%;
          }
        }
        &--fish-5 {
          bottom: 15rem;
          left: 5%;
          z-index: -1;
          width: 20rem;
          display: none;

          @include responsive(small) {
            display: block;
            width: 10%;
          }
        }
        &--ship {
          bottom: 0;
          right: 0;
          z-index: -1;
          width: 40rem;

          @include responsive(small) {
            width: 50%;
          }
        }
      }
    }

    .banner-run {
      padding: $space-5 0;
      background-color: $white;
      border-bottom: 4px solid $black;
      border-top: 4px solid $black;

      &--bottom {
        border-top: 3px solid $black;
      }

      .item-banner {
        display: flex;
        align-items: center;
        gap: $space-2;

        .icon-banner {
          width: 4.8rem;
          margin: 0 $space-2;
        }
      }
    }

    .card-wrapper {
      // background: linear-gradient(180deg, #c7eeff 0%, #77deff 100%);
      padding: 5rem 0 35rem;

      @include responsive(small) {
        padding: 12rem 0 35rem;
        width: 100%;
      }

      .card-container {
        @include container;
        display: flex;
        justify-content: center;
        margin-top: 5rem;

        // h1 {
        //   @include titleText;
        //   text-align: center;
        // }

        .text-title {
          width: 80%;
          padding-top: 15rem;
          margin-bottom: 12rem;

          @include responsive(small) {
            width: 100%;
          }
        }
      }

      .card-content {
        @include container;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: $space-52;
        margin-bottom: 12rem;

        @include responsive(tablet) {
          gap: $space-6;
          flex-direction: row;
        }

        @include responsive(small) {
          gap: $space-52;
          flex-direction: row;
        }

        &--right,
        &--left {
          display: flex;
          flex-direction: column;
          gap: $space-52;
        }

        &--right {
          @include responsive(tablet) {
            margin-top: 15rem;
          }
        }
      }

      .action-explore {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: $space-8;
        @include container;

        @include responsive(small) {
          flex-direction: row;
        }

        h6 {
          // margin-bottom: $space-8;
          color: $white;

          @include responsive(small) {
            margin-bottom: 0;
          }
        }

        .dagora-button {
          width: 100%;
          padding: $space-4 $space-12;
          height: 6rem;
          // pointer-events: none;
          // opacity: 0.8;
          flex-direction: column;

          span {
            font-size: 1rem;
          }

          @include responsive(tablet) {
            max-width: 30rem;
          }

          @include responsive(small) {
            height: unset;
            padding: $space-5 $space-12;
          }
        }

        .icon-logo {
          width: 11rem;
        }
      }
    }

    .info-wrapper {
      // background: linear-gradient(180deg, #7adfff 0%, #5cd4ff, #3bc0ff, #1cacff, #1494ff, #1595ff, #097aff 100%);

      // h1 {
      //   text-align: center;
      //   @include titleText;
      //   @include container;
      // }

      .text-title-box {
        @include container;
        margin-bottom: 20rem;
        display: flex;
        justify-content: center;

        .text-title-2 {
          width: 80%;

          @include responsive(tablet) {
            width: 100%;
          }
        }
      }

      .info-content {
        @include responsive(small) {
          @include container;
        }

        .info-item {
          margin-bottom: 30rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .roadmap-wrapper {
      // padding: 0 0 25rem;
      margin-top: 10rem;
      height: 20vh;
      display: flex;
      align-items: center;

      @include responsive(tablet) {
        margin-top: 0;
        // padding: 25rem 0;
      }

      .roadmap {
        @include container;
        display: flex;
        align-items: center;
        justify-content: center;

        @include responsive(tablet) {
          flex-wrap: nowrap;
          justify-content: center;
        }

        .roadmap-content {
          width: 80%;
          flex-wrap: wrap;
          justify-content: center;
          display: flex;
          align-items: center;
          gap: $space-6;

          @include responsive(tablet) {
            flex-wrap: nowrap;
            justify-content: unset;
          }

          .icon-fish {
            width: 18rem;
            margin-bottom: $space-8;

            @include responsive(tablet) {
              margin-bottom: 0;
              width: 15rem;
            }

            @include responsive(small) {
              width: 18rem;
            }
          }

          h3 {
            @include titleText;
            text-align: center;

            @include responsive(tablet) {
              text-align: unset;
            }
          }

          .text-box {
            width: 100%;

            .text-title-3 {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
